<template>
    <v-layout wrap>
        <v-flex class="d-flex justify-space-between mx-5" xs12>
            <span class="display-1 pb-5 font-weight-medium"
                >Editează Primarul</span
            >
            <div>
                <v-btn @click="save" class="mr-5" color="secondary"
                    >Salvează</v-btn
                >
                <v-btn @click="back" color="secondary">Înapoi</v-btn>
            </div>
        </v-flex>
        <v-flex class="mx-5" xs12>
            <v-card class="pa-5">
                <v-layout>
                    <v-flex lg6 space-between xs12>
                        <v-flex>
                            <v-text-field
                                :error-messages="errors.name"
                                @input="delete errors.name"
                                label="Nume"
                                v-model="data.name"
                            ></v-text-field>
                        </v-flex>
                        <v-flex>
                            <v-text-field
                                :error-messages="errors.phone"
                                @input="delete errors.phone"
                                label="Telefon"
                                v-model="data.phone"
                            ></v-text-field>
                        </v-flex>
                        <v-flex>
                            <v-text-field
                                :error-messages="errors.email"
                                @input="delete errors.email"
                                label="Email"
                                v-model="data.email"
                            ></v-text-field>
                        </v-flex>
                        <v-flex>
                            <v-text-field
                                :error-messages="errors.party"
                                @input="delete errors.party"
                                label="Partid"
                                v-model="data.party"
                            ></v-text-field>
                        </v-flex>
                    </v-flex>

                    <v-divider class="mx-3" vertical></v-divider>
                    <v-flex lg6 xs12>
                        <v-flex>
                            <v-file-input
                                :error-messages="errors.image"
                                @change="loadImage"
                                accept="image/*"
                                label="Poză primar"
                                v-model="data.image"
                            ></v-file-input>
                        </v-flex>
                        <v-flex class="text-center">
                            <img
                                :src="imageSrc"
                                height="300px"
                                id="preview"
                                style="border-radius: 100%"
                                width="300px"
                            />
                        </v-flex>
                    </v-flex>
                </v-layout>
                <v-divider class="my-10"></v-divider>
                <v-layout mb-5 wrap>
                    <v-flex mb-5 xs12>
                        <span class="ma-0 headline"
                            >Atribuțiile Primarului</span
                        >
                    </v-flex>
                    <v-flex>
                        <vue-editor v-model="tasks"></vue-editor>
                    </v-flex>
                </v-layout>
                <v-divider class="my-10"></v-divider>
                <v-layout mb-5 wrap>
                    <v-flex class="d-flex justify-space-between">
                        <span class="ma-0 headline"
                            >Curiculum Vitae Primar</span
                        >
                        <v-btn @click="add" color="secondary" small
                            >Adaugă intrare in CV</v-btn
                        >
                    </v-flex>
                </v-layout>
                <v-layout :key="index" v-for="(line, index) in cv">
                    <v-flex class="pr-5" xs4>
                        <v-text-field
                            label="Nume intrare"
                            solo
                            v-model="line.key"
                        ></v-text-field>
                    </v-flex>
                    <v-flex class="pl-5" xs8>
                        <v-textarea
                            label="Detalii"
                            solo
                            v-model="line.value"
                        ></v-textarea>
                    </v-flex>
                </v-layout>
                <v-card-text>
                    <v-flex class="mt-10 text-center">
                        <v-btn @click="save" color="secondary" width="200px"
                            >Salvează</v-btn
                        >
                    </v-flex>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import displayErrors from "@/mixins/displayErrors";
import { VueEditor } from "vue2-editor";

export default {
    name: "EditMayor",
    metaInfo: {
        title: "Primar -"
    },
    data: () => ({
        data: {},
        cv: [],
        tasks: ""
    }),
    components: { VueEditor },
    mixins: [displayErrors],
    computed: {
        imageSrc() {
            return this.data.src
                ? this.data.src
                : `${require("@/assets/person_placeholder.png")}`;
        }
    },
    methods: {
        async getData() {
            const response = await this.axios.get("/mayor");
            this.data = response.data;
            if (this.data.meta) {
                if (this.data.meta.cv) {
                    this.cv = this.data.meta.cv;
                }

                if (this.data.meta.tasks) {
                    this.tasks = this.data.meta.tasks;
                }
            }
        },
        back() {
            this.$router.go(-1);
        },
        loadImage() {
            delete this.errors.image;
            const file = this.data.image;
            const outImage = "preview";
            if (FileReader && file) {
                const fr = new FileReader();
                fr.onload = function() {
                    document.getElementById(outImage).src = fr.result;
                };
                fr.readAsDataURL(file);
            }
        },
        async save() {
            const formData = new FormData();
            Object.keys(this.data).forEach(key =>
                formData.append(key, this.data[key])
            );
            formData.append(
                "meta",
                JSON.stringify({ cv: this.cv, tasks: this.tasks })
            );
            try {
                await this.axios.post("/mayor", formData, {
                    headers: {
                        "Content-Type": `multipart/form-data;`
                    }
                });
                this.back();
            } catch (e) {
                console.log(e);
            }
        },
        add() {
            this.cv.splice(this.cv.length, 1, { key: "", value: "" });
        }
    },
    mounted() {
        this.getData();
    }
};
</script>
